@use '@ngxpert/hot-toast/src/styles/styles.scss';

/* You can add global styles to this file, and also import other style files */
@import '@material/layout-grid/dist/mdc.layout-grid.css';
@import 'theme';
html,
body {
  height: 100%;
  margin: 0;
}

.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}
.calendar-container {
  .calendar-header-item {
    a {
      font-weight: normal;
      text-transform: capitalize;
    }
  }
  .fc-daygrid-day-number {
    padding: 5px 6px;
  }
  .fc-day-today {
    background-color: #fafafa !important;
    .fc-daygrid-day-number {
      border: solid 1px $primary-color;
      border-radius: 20px;
      background-color: $primary-color;
      color: white;
    }
  }
}

.chartContainer canvas {
  width: auto;
}

.chartContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
